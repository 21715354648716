<template>
  <div class="list">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaName" label="影院名称">
            <a-input v-model:value="formState.cinemaName" placeholder="请输入影院名称" />
          </a-form-item>
          <a-form-item class="ui-form__item" name="contactInfo" label="预约人/电话">
            <a-input v-model:value="formState.contactInfo" placeholder="请输入预约人电话" />
          </a-form-item>
          <a-form-item label="状态" name="status" class="ui-form__item">
            <a-select v-model:value="formState.status" style="width: 180px;" allow-clear placeholder="请输入">
              <a-select-option :value="0">未处理</a-select-option>
              <a-select-option :value="2">已拒绝</a-select-option>
              <a-select-option :value="1">已同意</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row v-show="showAll">
          <a-form-item class="ui-form__item" name="createTime" label="申请时间">
            <a-range-picker v-model:value="formState.createTime"></a-range-picker>
          </a-form-item>
          <a-form-item class="ui-form__item" name="useTime" label="使用时间">
            <a-range-picker v-model:value="formState.useTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a style="margin-left: 20px;" @click="showAll = !showAll">
              {{ showAll ? "收起" : "展开" }}
              <Icon v-show="!showAll" icon="DownOutlined"></Icon>
              <Icon v-show="showAll" icon="UpOutlined"></Icon>
            </a>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <a-table style="margin-top: 10px;" :dataSource="list" :pagination="pagination" :columns="columns"
               rowKey="id"
               size="middle"
               :scroll="{ x: 1500 }">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'createTime'">
            {{ transDateTime(record.createTime) }}
          </template>
          <template v-if="column.key === 'useTime'">
            {{ transDateTime(record.useTime) }}
          </template>
          <template v-if="column.key === 'status'">
            <div v-if="record.status === 0">未处理</div>
            <div v-if="record.status === 1">已同意</div>
            <div v-if="record.status === 2">已拒绝</div>
          </template>
          <template v-if="column.key === 'action'">
            <div>
              <a-dropdown>
                <a-button @click.prevent>操作
                  <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['cinema_diy_list_agree']" v-show="record.status === 0" @click="onAgree(record)">
                      <a-menu-item>
                        <a>同意</a>
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_diy_list_refuse']" v-show="record.status === 0" @click="onRefuse(record)">
                      <a-menu-item>
                        <a>拒绝</a>
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_diy_list_revokeAgree']" v-show="record.status === 1" @click="onRevoke(record)">
                      <a-menu-item>
                        <a>撤销同意</a>
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_diy_list_revokeRefuse']" v-show="record.status === 2" @click="onRevoke(record)">
                      <a-menu-item>
                        <a>撤销拒绝</a>
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import {getCinemaServiceApplyList, updateCinemaServiceApply} from "@/service/modules/cinema";

export default {
  name: "list",
  components: {
    Icon
  },
  data() {
    return {
      id: 0,
      status: '',
      loading: false,
      showAll: false,
      formState: {

      },
      searchData: {},
      applyTime: [],
      usageTime: [],
      list: [],
      columns: [
        {
          title: "预约人",
          dataIndex: "contactName",
        },
        {
          title: "预约人电话",
          dataIndex: "contactPhone",
        },
        {
          title: "申请影院",
          dataIndex: "cinemaName",
        },
        {
          title: "申请日期",
          key: "createTime",
        },
        {
          title: "使用日期",
          key: "useTime",
        },
        {
          title: "用途",
          dataIndex: "useType",
        },
        {
          title: "状态",
          key: "status",
        },
        {
          title: "操作",
          key: "action",
          fixed: 'right',
          width: 120
        }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onAgree(item) {
      this.id = item.id;
      this.onUpdate('同意');
    },
    onRefuse(item) {
      this.id = item.id;
      this.onUpdate('拒绝');
    },
    onRevoke(item) {
      this.id = item.id;
      if(item.status === 1) {
        this.onUpdate('撤销同意');
      } else {
        this.onUpdate('撤销拒绝');
      }
    },
    async getData() {
      if(this.searchData.createTime && this.searchData.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      if(this.searchData.useTime && this.searchData.useTime.length === 2) {
        this.searchData.useStartTime = this.moment(this.formState.useTime[0].endOf('day')).unix();
        this.searchData.useEndTime = this.moment(this.formState.useTime[1].endOf('day')).unix();
      }
      delete this.searchData.createTime;
      delete this.searchData.useTime;
      this.loading = true;
      try {
        let ret = await getCinemaServiceApplyList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onUpdate(item) {
      if(item === '撤销同意') this.status = 0;
      if(item === '撤销拒绝') this.status = 0;
      if(item === '同意') this.status = 1;
      if(item === '拒绝') this.status = 2;
      this.$confirm({
        title: '提示',
        content: '确认要' + item + '吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await updateCinemaServiceApply({
              id: this.id,
              status: this.status
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.status = '';
              this.getData();
            }
          } catch(e) {
            this.loading = false
          }
        }
      })
    }
  }
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 20px;
}
</style>